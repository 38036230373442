<template>
  <v-app id="welcome">
    <v-row align="center" class="mygradient" fluid>
      <v-col cols="12">
        <v-row fluid>
          <v-col cols="12">
            <v-row justify="center">
              <v-col class="text-center">
                <v-icon style="font-size: 3.5rem !important" color="black">fa-satellite</v-icon>
              </v-col>
            </v-row>
            <v-row justify="center" class="mb-3">
              <v-col cols="12">
                <div class="display-1 text-center">{{ $t('notFoundView.title') }}</div>
                <div class="subtitle-1 text-center">{{ $t('notFoundView.subtitle') }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-8">
                <v-row align="center" justify="center">
                  <v-btn class="save"
                         color="accent"
                         @click="onClickHome()">
                    <v-icon left>fa-home</v-icon>
                    {{ $t('notFoundView.home') }}
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center" class="my-3">
          <v-col cols="12">
            <MadeWithLove class="pt-5"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import MadeWithLove from '@/apps/core/components/MadeWithLove'
import { IS_LOR } from '@/variables'

export default {
  name: 'NotFound',
  components: { MadeWithLove },
  methods: {
    onClickHome () {
      if (!IS_LOR) {
        this.$router.push({ name: 'cosmos' })
      } else {
        window.open('/campaigns/')
      }
    }
  }
}
</script>

<style scoped>
  .mygradient {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, var(--v-primary-lighten3) 100%);
  }
</style>
